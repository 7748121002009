import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FC } from 'react';
import Map, { MapRef, Marker, Popup } from 'react-map-gl';
import PropertyQuickViewModal from './modals/PropertyQuickViewModal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import mapboxgl, { PointLike } from 'mapbox-gl';
import AvmCompMapMarker from '../property-detail-page/AvmCompMapMarker';
import { abandonFocus, setFocusedIndex } from '../../redux/state/avmMapSlice';
import { AddressType, AvmCompForMap, PropertyDataProps } from '../../utility/types';

export type AvmMapProps = {
  property: PropertyDataProps,
  comps?: AvmCompForMap[]
};

const AvmMap: FC<AvmMapProps> = ({ property, comps }) => {
  const dispatch = useDispatch<AppDispatch>();
  const focusedIndex = useSelector((state: RootState) => state.avmMap.focusedIndex);
  const mapKey = useSelector((state: RootState) => state.map.key);
  const markersRef = useRef({});
  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
    }
  }, []);

  const [ mapNode, setMapNode ] = useState(null);
  const [ isAvmComp, setIsAvmComp ] = useState(false);
  const [ popupInfo, setPopupInfo ] = useState<AddressType>(null);
  const [ popupLoading, setPopupLoading ] = useState(false);
  const [ previousCenter, setPreviousCenter ] = useState(null);
  const [ isSatelliteView, setIsSatelliteView ] = useState(false);
  const [ mapResizeNeeded, setMapResizeNeeded ] = useState(false);
  const [ showActiveComps, setShowActiveComps ] = useState(false);

  const startingCenter = {
    lat: +property?.address?.latitude,
    lng: +property?.address?.longitude
  };

  useEffect(() => {
    if (popupInfo) {
      const comp = comps?.find(comp => comp.property.id === popupInfo.property_id);
      setIsAvmComp(!!comp);
    }
  }, [ comps, popupInfo ]);

  const clearSelection = () => {
    dispatch(abandonFocus());
    dispatch(setFocusedIndex(null));
    setPopupInfo(null);
    mapNode.setCenter(previousCenter);
  };

  const fitMapToComps = () => {
    if (comps === undefined || comps === null) {
      return;
    }
    const latitudes = markers.map(marker => marker.props.latitude);
    const longitudes = markers.map(marker => marker.props.longitude);
    const sw = new mapboxgl.LngLat(Math.min(...longitudes), Math.min(...latitudes));
    const ne = new mapboxgl.LngLat(Math.max(...longitudes), Math.max(...latitudes));
    const bounds = new mapboxgl.LngLatBounds(sw, ne);
    mapNode.getMap().fitBounds(bounds, { padding: 50 });
  };

  const getMarkerHtml = (address, index = undefined, isActive = false) => {
    return (
      <Marker
        longitude={address.longitude}
        latitude={address.latitude}
        key={address.id}
        ref={el => markersRef.current[address.id] = el}
        onClick={e => {
          e.originalEvent.stopPropagation();

          setPreviousCenter(mapNode.getCenter());
          const neSWBounds = mapNode.getBounds();
          const getDistanceBetweenEastAndWestMapEdge = neSWBounds._ne.lng - neSWBounds._sw.lng;
          const get45PercentOfThatDistance = getDistanceBetweenEastAndWestMapEdge * .45;
          mapNode.setCenter([ address.longitude + get45PercentOfThatDistance, address.latitude ]);
          setPopupInfo(address);
        }}
      >
        <AvmCompMapMarker index={index} loading={popupLoading} isActive={isActive} />
      </Marker>
    );
  };

  const markers = useMemo(() => {
    let activeIndex = 0;
    let inactiveIndex = 0;
    let filteredComps = comps;

    if (!showActiveComps && filteredComps) {
      filteredComps = filteredComps?.filter(comp => !comp.isActive);
    }

    const markers = filteredComps?.map((comp) => {
      const address = comp?.property?.address;
      if (address.latitude && address.longitude) {
        const index = comp.isActive ? activeIndex++ : inactiveIndex++;
        return getMarkerHtml(address, index, comp.isActive);
      }
    }) ?? [].reverse();

    const address = property?.address;
    if (address) {
      markers.push(getMarkerHtml(address));
    }
    return markers;
  }, [ comps, focusedIndex, popupLoading, showActiveComps ]);

  useEffect(() => {
    if (mapResizeNeeded) {
      fitMapToComps();
      setMapResizeNeeded(false);
    }
  }, [ markers ]);

  const toggleActiveComps = () => {
    setShowActiveComps(!showActiveComps);
    setMapResizeNeeded(true);
  };

  const toggleSatelliteView = () => {
    const newStyle = isSatelliteView ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/standard-satellite';
    mapNode.getMap().setStyle(newStyle);
    setIsSatelliteView(!isSatelliteView);
  };

  return (
    <div className="h-full relative">
      <div className="absolute z-10 top-2 right-2 bg-transparent text-indigo-600">
        <button
          className="py-0.5 mr-2 px-1 rounded-sm bg-neutral-50 border-2 border-indigo-600 hover:text-indigo-800 hover:border-indigo-800"
          onClick={toggleSatelliteView}
        >
          {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
        </button>
        {comps &&
          <button
            className="py-0.5 px-1 rounded-sm bg-neutral-50 border-2 border-indigo-600 hover:text-indigo-800 hover:border-indigo-800"
            onClick={toggleActiveComps}
          >
            {showActiveComps ? 'Hide Active Comps' : 'Show Active Comps'}
          </button>}
      </div>
      <Map
        ref={mapRef}
        mapboxAccessToken={mapKey}
        initialViewState={{
          longitude: startingCenter.lng,
          latitude: startingCenter.lat,
          zoom: 10
        }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onLoad={fitMapToComps}
      >
        {markers}
        {popupInfo && (
          <Popup
            longitude={+popupInfo.longitude}
            latitude={+popupInfo.latitude}
            maxWidth="320px"
            anchor='left'
            offset={[ 18, 0 ] as PointLike}
            closeButton={false}
            closeOnMove={true}
            onClose={clearSelection}
          >
            <PropertyQuickViewModal
              propertyId={popupInfo.property_id}
              setPopupLoading={setPopupLoading}
              isAvmComp={isAvmComp}
            />
          </Popup>
        )}
      </Map>
    </div>);
};

export default AvmMap;
