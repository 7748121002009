import { Feature, Polygon } from '@turf/turf';
import { BooleanToggleValuesType, DateRangeSelectorValuesType, FilterDescriptor, FilterValuesType, MultiSelectorValuesType, RangeValuesType, StringSelectorValuesType } from './Filtering';
import { isFilterActive } from './filterUtils';

export interface FilterStringGeneratorType {
  id: string;
  values: FilterDescriptor['values'];
  filter_type: FilterDescriptor['filter_type'];
}

export function generateRansackObject (
  filters: Record<string, FilterDescriptor>,
  sorting?: { field: string; direction: 'asc' | 'desc' },
  gisFilter?: { lasso: Feature<Polygon>[], areaToBeSearched: Feature<Polygon>[] }
): { q: any, w?: any, s?: any, lasso?: any } {
  // c is for custom sort logic on the backend (eg calculated fields)
  const ransackObj = { q: {}, w: {}, s: {}, m: {}, c:{}, gisFilter: {} };

  for (const [ id, filter ] of Object.entries(filters)) {
    if (!isFilterActive(filter)) {
      continue; // Skip inactive filters
    }
    const ransackKey = filter.ransack_key;
    if (ransackKey === 'custom_auction_number') {
      ransackObj.c[ransackKey] = (filter.values as StringSelectorValuesType).query;
      continue;
    }
    // avm_price_per_sqft
    if (ransackKey === 'avm_result'
      || ransackKey === 'potential_equity'
      || ransackKey === 'avm_result_per_sqft'
      || ransackKey === 'avm_price_per_sqft') {
      if ((filter.values as RangeValuesType).min === (filter.values as RangeValuesType).max) {
        ransackObj.c[`${ransackKey}_eq`] = (filter.values as RangeValuesType).min;
      } else {
        if ((filter.values as RangeValuesType).min !== (filter.defaultValues as RangeValuesType).min) {
          ransackObj.c[`${ransackKey}_gteq`] = (filter.values as RangeValuesType).min;
        }
        if ((filter.values as RangeValuesType).max !== (filter.defaultValues as RangeValuesType).max) {
          ransackObj.c[`${ransackKey}_lteq`] = (filter.values as RangeValuesType).max;
        }
      }
      continue;

    }
    if (id === 'proposed_terms') {
      const multiSelectorValues = (filter.values as MultiSelectorValuesType).state;
      if (multiSelectorValues && multiSelectorValues?.length > 0) {
        ransackObj.q['proposed_terms_cont_any'] = multiSelectorValues.map((state: any) => {
          const { code } = state;
          return code;
        });
      }
      continue;
    }
    switch (filter.filter_type) {
    case 'TieredRangeSelectorFilterType':
    case 'RangeInputSelectorFilterType':
    case 'RangeSliderSelectorFilterType':
      if ((filter.values as RangeValuesType).min === (filter.values as RangeValuesType).max) {
        ransackObj.q[`${id}_eq`] = (filter.values as RangeValuesType).min;
      } else {
        if ((filter.values as RangeValuesType).min !== (filter.defaultValues as RangeValuesType).min) {
          ransackObj.q[`${id}_gteq`] = (filter.values as RangeValuesType).min;
        }
        if ((filter.values as RangeValuesType).max !== (filter.defaultValues as RangeValuesType).max) {
          ransackObj.q[`${id}_lteq`] = (filter.values as RangeValuesType).max;
        }
      }
      break;
    case 'MultiSelectorFilterType':
      const multiSelectorValues = (filter.values as MultiSelectorValuesType).state;
      if (multiSelectorValues && multiSelectorValues?.length > 0) {
        ransackObj.m[id] = multiSelectorValues.map((state: any) => {
          const { code } = state;
          return code;
        });
      }
      break;
    case 'BooleanToggleFilterType':
      const value = (filter.values as BooleanToggleValuesType).state;
      ransackObj.q[`${id}_eq`] = value ? 'true' : value === false ? 'false' : 'null';
      break;
    case 'StringSelectorFilterType':
      ransackObj.q[ransackKey] = (filter.values as StringSelectorValuesType).query;
      break;
    case 'WantedMultiSelectorFilterType':
      const wants = (filter.values as MultiSelectorValuesType).state.map(state => state.code === null ? 'null' : state.code);
      if (wants.length > 0) {
        ransackObj.w = {
          ...ransackObj.w,
          wanted: wants
        };
      }
      break;
    case 'FavoriteTriStateSelectorFilterType':
      ransackObj.w = {
        ...ransackObj.w,
        favorite: (filter.values as BooleanToggleValuesType).state ? 'true' : 'false'
      };
      break;
    case 'DateRangeSelectorFilterType':
      if ((filter.values as DateRangeSelectorValuesType).startDate !== (filter.defaultValues as DateRangeSelectorValuesType).startDate) {
        ransackObj.q[`${id}_gteq`] = (filter.values as DateRangeSelectorValuesType).startDate;
      }
      if ((filter.values as DateRangeSelectorValuesType).endDate !== (filter.defaultValues as DateRangeSelectorValuesType).endDate) {
        ransackObj.q[`${id}_lteq`] = (filter.values as DateRangeSelectorValuesType).endDate;
      }
      if ((filter.values as DateRangeSelectorValuesType).startDate === (filter.defaultValues as DateRangeSelectorValuesType).startDate && (filter.values as DateRangeSelectorValuesType).endDate === (filter.defaultValues as DateRangeSelectorValuesType).endDate) {
        ransackObj.q[`${id}_eq`] = (filter.values as DateRangeSelectorValuesType).startDate;
      }
      break;
    default:
      console.error('Unrecognized Filter type');
      continue;
    }
  }

  // Adding sorting support
  if (sorting && sorting.field && sorting.direction) {
    let sortField = sorting.field;
    if (sortField.includes('_cont')) {
      sortField = sortField.replace('_cont', '');
    }
    ransackObj.s = `${sortField} ${sorting.direction}`;
  }

  if (gisFilter) {
    ransackObj.gisFilter = gisFilter;
  }

  if (Object.keys(ransackObj.s).length === 0) {
    delete ransackObj.s; // Remove 's' key if it's empty
  }
  if (Object.keys(ransackObj.w).length === 0) {
    delete ransackObj.w; // Remove 'w' key if it's empty
  }
  if (Object.keys(ransackObj.q).length === 0) {
    delete ransackObj.q; // Remove 'q' key if it's empty
  }
  if (Object.keys(ransackObj.m).length === 0) {
    delete ransackObj.m; // Remove 'm' key if it's empty
  }
  if (Object.keys(ransackObj.c).length === 0) {
    delete ransackObj.c; // Remove 'c' key if it's empty
  }
  if (Object.keys(ransackObj.gisFilter).length === 0) {
    delete ransackObj.gisFilter;
  }
  return ransackObj;
}
