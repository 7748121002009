// Utility functions to check if filters are active or default
import {
  BooleanToggleValuesType,
  BooleanTriStateSelectorValuesType,
  DateRangeSelectorValuesType,
  FilterDescriptor,
  FilterType,
  MultiSelectorValuesType,
  RangeValuesType,
  StringSelectorValuesType,
} from './Filtering';

export const getActiveFilterIds = (filters: Record<string, FilterDescriptor>): Set<string> => {
  return new Set(Object.entries(filters).filter(([ _, filter ]) => isFilterActive(filter)).map(([ id ]) => id));
};

export const isFilterActive = (filter: FilterDescriptor): boolean => {
  if (!filter || !filter.values) {
    return false;
  }

  return JSON.stringify(filter.values) !== JSON.stringify(filter.emptyValues);
};

export const areFiltersDefault = (filters: Record<string, FilterDescriptor>): boolean => {
  return Object.values(filters).every((filter) => {
    const isDefault = JSON.stringify(filter.values) === JSON.stringify(filter.defaultValues);

    // Only return true if the filter is either set to default or is empty
    return isDefault;
  });
};

export const formatFilterValues = (filter: FilterDescriptor): string => {
  switch (FilterType[filter.filter_type]) {
  case FilterType.TieredRangeSelectorFilterType:
  case FilterType.RangeInputSelectorFilterType:
  case FilterType.RangeSliderSelectorFilterType: {
    const rangeValues = filter.values as RangeValuesType;
    const unit = filter.metadata?.unit ? ` ${filter.metadata.unit}` : '';
    return `${filter.title} - ${rangeValues.min}${unit} - ${rangeValues.max}${unit}`;
  }

  case FilterType.BooleanTriStateSelectorFilterType: {
    const booleanTriStateValues = filter.values as BooleanTriStateSelectorValuesType;
    let stateString = 'Indeterminate';
    if (booleanTriStateValues.state === true) stateString = 'Yes';
    if (booleanTriStateValues.state === false) stateString = 'No';
    return `${filter.title} - ${stateString}`;
  }

  case FilterType.BooleanToggleFilterType: {
    const booleanToggleValues = filter.values as BooleanToggleValuesType;
    return `${filter.title} - ${booleanToggleValues.state ? 'Yes' : 'No'}`;
  }

  case FilterType.MultiSelectorFilterType: {
    const multiSelectorValues = filter.values as MultiSelectorValuesType;
    const selectedValues = multiSelectorValues.state
      .map(option => option.selectedLabelName || option.name)
      .join(', ');
    return `${filter.title} - ${selectedValues}`;
  }

  case FilterType.StringSelectorFilterType: {
    const stringSelectorValues = filter.values as StringSelectorValuesType;
    return `${filter.title} - ${stringSelectorValues.query}`;
  }

  case FilterType.DateRangeSelectorFilterType: {
    const dateRangeValues = filter.values as DateRangeSelectorValuesType;
    return `${filter.title} - ${dateRangeValues.startDate} to ${dateRangeValues.endDate}`;
  }

  case FilterType.FavoriteTriStateSelectorFilterType: {
    const favoriteTriStateValues = filter.values as BooleanToggleValuesType;
    return `${filter.title} - ${favoriteTriStateValues.state ? 'True' : 'False'}`;
  }

  case FilterType.WantedMultiSelectorFilterType: {
    const wants = (filter.values as MultiSelectorValuesType).state.map(state => state.code === null ? 'null' : state.code);
    return `${filter.title} - ${wants.join(', ')}`;
  }

  default:
    return `${filter.title} - other: ${filter.filter_type}`;
  }
};
