import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { AddressType, PropertyDataProps } from '../../utility/types';
import React from 'react';
import Map, { MapRef, Marker, Popup } from 'react-map-gl';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import PropertyQuickViewModal from './modals/PropertyQuickViewModal';
import { polygon } from '@turf/helpers';
import { useFilteringContext } from '../../contexts/Filtering';
import useGetProperties from '../../hooks/api/Property/useGetProperties';
import { IconHouseFill, Loader, LoadingSpinner } from '../icons/OurIcons';
import { getMapboxBoundingBox, getMapboxPopupOrientationAndOffset } from '../../utility/utility';

export type NearbyListingsMapProps = {
  property: PropertyDataProps
};

const NearbyListingMap: FC<NearbyListingsMapProps> = ({ property }) => {
  const mapKey = useSelector((state: RootState) => state.map.key);
  const markersRef = useRef({});
  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
      setMapBoundingBox(getMapboxBoundingBox(node));
    }
  }, []);

  const [ isSatelliteView, setIsSatelliteView ] = useState(false);
  const [ mapBoundingBox, setMapBoundingBox ] = useState(null);
  const [ mapNode, setMapNode ] = useState(null);
  const [ popupOffset, setPopupOffset ] = useState(0);
  const [ popupInfo, setPopupInfo ] = useState<AddressType>(null);
  const [ popupLoading, setPopupLoading ] = useState(false);
  const [ popupOrientation, setPopupOrientation ] = useState(null);
  const [ ransackObj, setRansackObj ] = useState(useFilteringContext().ransackObj);

  const startingCenter = {
    lat: +property?.address?.latitude,
    lng: +property?.address?.longitude
  };

  const { propertiesData, loadingProperties, pendingProperties } = useGetProperties(null, ransackObj, 500, 1, null);

  const setPopupOrientationAndOffset = (id) => {
    let orientation, offset;
    [ orientation, offset ] = getMapboxPopupOrientationAndOffset(markersRef.current[id], mapBoundingBox, 320);

    setPopupOrientation(orientation);
    setPopupOffset(offset);
  };

  const calculateNearbyListings = () => {
    if (!mapNode || !property) {
      return [];
    }

    const bounds = mapNode.getBounds();

    const ne = bounds.getNorthEast();
    const nePoint = [ ne.lng, ne.lat ];
    const nw = bounds.getNorthWest();
    const nwPoint = [ nw.lng, nw.lat ];
    const se = bounds.getSouthEast();
    const sePoint = [ se.lng, se.lat ];
    const sw = bounds.getSouthWest();
    const swPoint = [ sw.lng, sw.lat ];

    const poly = [ polygon([ [ nePoint, nwPoint, swPoint, sePoint, nePoint ] ]) ];

    if (poly) {
      setRansackObj({ ...ransackObj, polys: poly });
    }
  };

  const toggleSatelliteView = () => {
    const newStyle = isSatelliteView ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/standard-satellite';
    mapNode.getMap().setStyle(newStyle);
    setIsSatelliteView(!isSatelliteView);
  };

  const clearSelection = () => {
    setPopupInfo(null);
  };

  const getMarkerHtml = (markerProperty) => {
    let markerIcon;
    const address = markerProperty.address;
    const isRent = markerProperty.sale_or_rent?.toUpperCase() === 'RENT';
    const backgroundColor = isRent ? 'bg-yellow-500' : 'bg-green-500';
    let shortenedPrice;
    if (markerProperty.list_price >= 100000000) { // > 100 Million
      shortenedPrice = `$${Math.round(markerProperty.list_price / 1000000)}M`;
    }
    if (markerProperty.list_price >= 10000000) { // > 10 Million
      shortenedPrice = `$${Math.round(markerProperty.list_price / 100000) / 10}M`;
    }
    else if (markerProperty.list_price >= 1000000) { // > 1 Million
      shortenedPrice = `$${Math.round(markerProperty.list_price / 10000) / 100}M`;
    }
    else if (markerProperty.list_price >= 100000) { // > 100k
      shortenedPrice = `$${Math.round(markerProperty.list_price / 1000)}k`;
    }
    else if (markerProperty.list_price >= 1000) { // > 1k
      shortenedPrice = `$${Math.round(markerProperty.list_price / 100) / 10}k`;
    }
    else {
      shortenedPrice = `$${markerProperty.list_price}`;
    }

    const dotStyling = 'flex items-center justify-center rounded-full drop-shadow fill-white text-white cursor-pointer text-lg';
    const priceContainerStyling = 'absolute mt-0.5 px-1 py-0.5 rounded-2xl';
    const priceStyling = 'bg-white px-1 leading-3 rounded-lg';

    if (address?.id !== property?.address?.id) {
      markerIcon = (
        <>
          <div className={`${dotStyling} w-4 h-4 ${backgroundColor}`}></div>
          {popupInfo && popupLoading && address.id === popupInfo.id && <div className="absolute w-10 -bottom-3 -right-3">
            <Loader />
          </div>}
          <div className={`${priceContainerStyling} ${backgroundColor} ${shortenedPrice.length > 3 ? '-translate-x-1/3' : '-translate-x-1/4'}`}>
            <p className={priceStyling}>{shortenedPrice}</p>
          </div>
        </>
      );
    }
    else {
      markerIcon = (
        <>
          <div className={`${dotStyling} w-6 h-6 bg-indigo-800`}>
            <IconHouseFill color="White" className='w-3/4 pb-px'/>
          </div>
          <div className={`${priceContainerStyling} bg-indigo-800 ${shortenedPrice.length > 3 ? '-translate-x-1/4' : '-translate-x-1.5'}`}>
            <p className={priceStyling}>{shortenedPrice}</p>
          </div>
        </>
      );
    }
    return (
      <Marker
        longitude={address.longitude}
        latitude={address.latitude}
        key={address.id}
        ref={el => markersRef.current[address.id] = el}
        onClick={e => {
          e.originalEvent.stopPropagation();
          setPopupOrientationAndOffset(address.id);
          setPopupInfo(address);
        }}
      >
        {markerIcon}
      </Marker>
    );
  };

  const markers = useMemo(() => {
    if (property && propertiesData) {
      const properties = propertiesData;
      const propertyIndex = properties.findIndex(prop => prop.id === property.id);
      if (propertyIndex !== -1) {
        properties.splice(propertyIndex, 1);
      }
      properties.push(property);

      const markers = properties?.map((prop) => {
        const address = prop?.address;
        if (address.latitude && address.longitude) {
          return getMarkerHtml(prop);
        }
      });
      return markers;
    }
  }, [ property, propertiesData, popupInfo, popupLoading ]);

  if (!propertiesData && (pendingProperties || loadingProperties)) {
    return (
      <div className='h-full w-full relative'>
        <div className='absolute w-full h-full z-10 bg-slate-500/20'>
          <div className='absolute w-[10%] h-[10%] top-[45%] right-[45%]'>
            <LoadingSpinner></LoadingSpinner>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='h-full relative'>
      <div className='flex justify-end p-2 absolute w-full pointer-events-none z-10'>
        <button className='py-0.5 px-1 pointer-events-auto rounded-sm bg-neutral-50 text-indigo-600 border-indigo-600 border-2 hover:text-indigo-800 hover:border-indigo-800'
          onClick={toggleSatelliteView}>
          {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
        </button>
      </div>
      {propertiesData?.length === 1 &&
        <div className='absolute top-[55%] left-1/2 -translate-x-1/2 z-10'>
          <h2 className={`justify-self-start ${isSatelliteView ? 'text-neutral-50 drop-shadow-[0px_0px_2px_black]' : 'text-neutral-950 drop-shadow-[0px_0px_2px_white]'}`}>
            No Nearby Listings
          </h2>
        </div>
      }
      <Map
        ref={mapRef}
        mapboxAccessToken={mapKey}
        initialViewState={{
          longitude: startingCenter.lng,
          latitude: startingCenter.lat,
          zoom: 14
        }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onLoad={calculateNearbyListings}
      >
        {markers}
        {popupInfo && (
          <Popup
            longitude={+popupInfo.longitude}
            latitude={+popupInfo.latitude}
            maxWidth="320px"
            anchor={popupOrientation}
            offset={popupOffset}
            closeButton={false}
            closeOnMove={true}
            onClose={clearSelection}
          >
            <PropertyQuickViewModal
              propertyId={popupInfo.property_id}
              setPopupLoading={setPopupLoading}
            />
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default NearbyListingMap;
