import { useMutation, useQueryClient } from '@tanstack/react-query';
import createSavedSearchCriteria from '../../../api/SearchCriteria/createSavedSearchCriteria';
import { useFilteringContext } from '../../../contexts/Filtering';
import { generateSavedSearchCriteriaRequestBody } from '../../../utility/generateSavedSearchCriteriaRequestBody';

export type generateSavedSearchCriteriaRequestBodyProps = {
  newSearchCriteriaName: string;
  ransackObj: any;
}
export const useCreateSavedSearchCriteria = () => {
  const queryClient = useQueryClient();
  const { ransackObj } = useFilteringContext();

  function createSavedSearchCriteriaWrapper(newSearchCriteriaName: string) {
    const requestBody = generateSavedSearchCriteriaRequestBody({
      newSearchCriteriaName,
      ransackObj,
    });
    return createSavedSearchCriteria(requestBody);
  }

  const createSavedSearchCriteriaMutation = useMutation({
    mutationFn: (newSearchCriteriaName: string) => createSavedSearchCriteriaWrapper(newSearchCriteriaName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'getSavedSearchCriteria' ] });
    },
  });

  return {
    createSavedSearchCriteria: createSavedSearchCriteriaMutation.mutateAsync,
    errorCreateSavedSearchCriteria: createSavedSearchCriteriaMutation.isError ? createSavedSearchCriteriaMutation.error : null,
    pendingCreateSavedSearchCriteria: createSavedSearchCriteriaMutation.isPending,
  };
};
