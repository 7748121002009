import React, { useRef, type FC } from 'react';
import { HomeIcon } from '@heroicons/react/24/outline';
import { SaveSearchCriteriaModalProps } from '../../../../utility/types';
import { useFilteringContext } from '../../../../contexts/Filtering';
import { isFilterActive } from '../../../../contexts/filterUtils';
import { formatFilterValues } from '../filters/utility';

const SaveCriteriaModal: FC<SaveSearchCriteriaModalProps> = ({ isOpen, onClose, onSubmit, handleNameChange }) => {
  const cancelButtonRef = useRef(null);
  const { state } = useFilteringContext();
  const { filters } = state;
  const activeFilters = Object.entries(filters).filter(([ id, filter ]) => isFilterActive(filter));

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
      <div className="flex min-h-screen items-center justify-center">
        <div id="save-criteria-modal" className="bg-white rounded-lg px-4 pb-4 pt-5 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <HomeIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="my-8 text-center sm:mt-5">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Do you want to save this search criteria?</h3>
              <div className="mt-2">
                <div id="SaveCriteriaModal-active-filters"
                  className="text-sm text-gray-500 max-h-60 overflow-y-auto"
                >
                  {
                    activeFilters.length ?
                      activeFilters.map(([ id, filter ]) => {
                        return (
                          <p key={id}>{filter.title} - {formatFilterValues(filter.values)}</p>
                        );
                      })
                      :
                      <p key="no-criteria">No criteria selected</p>
                  }
                </div>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="nameInput" className="mr-8">Name:</label>
            <input
              type="text"
              id="name-input"
              placeholder="New Search Criteria"
              className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 container"
              onChange={handleNameChange}
            />
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={onSubmit}
            >
              Save Search Criteria
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={onClose}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveCriteriaModal;
