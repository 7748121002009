/**
 * Custom hook for interacting with browser storage (localStorage and sessionStorage).
 * - localStorage persists data across sessions (even after closing the browser).
 * - sessionStorage retains data only for the current session (lost when the tab/window is closed).
 * Both types are scoped to the domain but sessionStorage is tab/window specific, while localStorage
 * is shared across tabs of the same domain.
 */
import { useState } from 'react';

/*
  Each time you use this hook, provide it with a unique key that identifies the segment of local storage that the
  component should interact with.

*/
function useSessionStorage<T>(key: string, initialValue: T) {
  // State to store the value
  const [ storedValue, setStoredValue ] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) as T : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error('Error reading from sessionStorage', error);
      return initialValue;
    }
  });

  // Function to save value to sessionStorage and state
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to sessionStorage
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error saving to sessionStorage', error);
    }
  };

  // Function to clear specific sessionStorage key
  const clearStorageKey = () => {
    try {
      window.sessionStorage.removeItem(key);
      setStoredValue(initialValue); // Optionally reset the state to initial value
    } catch (error) {
      console.error('Error removing sessionStorage key', error);
    }
  };

  const clearSpecificSessionStorage = (key: string) => {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing sessionStorage key', error);
    }
  };

  const setSpecificSessionStorage = (key: string, value: T) => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error removing sessionStorage key', error);
    }
  };

  return { storedValue, setValue, clearStorageKey, clearSpecificSessionStorage, setSpecificSessionStorage };
}

export default useSessionStorage;
