import { DateRangeSelectorValuesType } from '../../../../contexts/Filtering';
import { BooleanToggleValuesType, BooleanTriStateSelectorValuesType, MultiSelectorValuesType, RangeValuesType, StringSelectorValuesType, TieredRangeSelectorValuesType } from '../../../../contexts/Filtering';

export function isBooleanTriStateSelectorType(values: any): values is BooleanTriStateSelectorValuesType {
  return values ? values && (values.state === true || values.state === false || values.state === null || values.state === undefined) : true;
}
export function isTieredRangeSelectorType(values: any): values is TieredRangeSelectorValuesType {
  return values ? values && typeof values.min === 'number' && typeof values.max === 'number' : true;
}

export function isRangeInputSelectorType(values: any): values is RangeValuesType {
  return values ? values && typeof values.min === 'number' && typeof values.max === 'number' : true;
}
export function isDateRangeSelectorType(values: any): values is DateRangeSelectorValuesType {
  return values ?
    (!isNaN(Date.parse(values.startDate)) &&
     !isNaN(Date.parse(values.endDate)))
    : false;
}
export function isMultiSelectorType(values: any): values is MultiSelectorValuesType {
  let pass = !!values?.state;
  if (values?.state && values?.state?.length) {
    pass = values.state.every(s => typeof s.name === 'string' && typeof s.code === 'string');
  }
  return  pass;
}
export function isBooleanToggleType(values: any): values is BooleanToggleValuesType {
  return typeof values === 'object' && values !== null && 'state' in values &&  (values.state === true || values.state === false || values.state === null || values.state === undefined);
}
export function isStringSelectorType(values: any): values is StringSelectorValuesType {
  return values ? values && typeof values.query === 'string' : true;
}

export function formatFilterValues(values: any): string {
  if (isBooleanToggleType(values)) {
    return values.state.toString();
  }
  if (isMultiSelectorType(values)) {
    return values.state.map((state: any) => {
      const { code } = state;
      return code;
    }).join(', ');
  }
  if (isRangeInputSelectorType(values)) {
    return `${values.min} - ${values.max}`;
  }
  if (isDateRangeSelectorType(values)) {
    return `${values.startDate} - ${values.endDate}`;
  }
  if (isStringSelectorType(values)) {
    return values.query;
  }
  if (isBooleanTriStateSelectorType(values)) {
    return values.state.toString();
  }
  if (isTieredRangeSelectorType(values)) {
    return `${values.min} - ${values.max}`;
  }
  return values ? values.toString() : '';

}

export interface RangeSelectorComponentProps {
  id: string;
  min: number;
  max: number;
  title: string;
  defaultMin: number;
  defaultMax: number;
  description: string;
  unit?: string;
  onUpdate: (id: string, newValues: RangeValuesType) => void;
}
export interface SearchStringComponentProps {
  id: string;
  title: string;
  description: string;
  query: string;
  defaultQuery: string;
  onUpdate: (id: string, newValues: StringSelectorValuesType) => void;
  className?: string;
}
export interface DateRangeSelectorProps {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  defaultStartDate: string;
  defaultEndDate: string;
  onUpdate: (id: string, newValues: DateRangeSelectorValuesType) => void;
}
export interface DualValueRangeCardProps {
  id: string;
  min: number;
  max: number;
  defaultMin: number;
  defaultMax: number;
  description: string;
  title: string;
  unit?: string;
  onUpdate: (id: string, newValues: RangeValuesType) => void;
}

export interface FavoriteTriStateSelectorProps {
  id: string;
  title: string;
  description: string;
  state: true | false | null;
  onUpdate: (id: string, newValue: BooleanTriStateSelectorValuesType) => void;
};

export interface WantedMultiSelectorProps {
  id: string;
  title: string;
  description: string;
  state: { name: string, code: string }[];
  options: { name: string, code: string }[];
  defaultValues: { name: string, code: string }[];
  onUpdate: (id: string, newValues: MultiSelectorValuesType) => void;
};

export interface BooleanToggleComponentProps {
  id: string;
  title: string;
  description: string;
  currValue: true | false ;
  onUpdate: (id: string, newValue: BooleanToggleValuesType) => void;
}

export interface MultiStateSelectSelectorComponentProps {
  id: string;
  title: string;
  description: string;
  state: { name: string, code: string }[];
  options: { name: string, code: string }[];
  itemTemplate?: any;
  selectedLabelName?: string;
  defaultValues: { name: string, code: string }[];
  onUpdate: (id: string, newValues: MultiSelectorValuesType) => void;
}

export interface BooleanTriStateSelectorComponentProps {
  id: string
  title: string;
  description: string;
  values: BooleanTriStateSelectorValuesType;
  defaultValues: BooleanTriStateSelectorValuesType;
  icons: { active: any; inactive: any; indeterminate: any };
  onUpdate: (id: string, newValues: BooleanTriStateSelectorValuesType) => void;
}

export const preprocessFilterData = (id: string, filterData: any) => {
  if (!filterData || !id) {
    return null;
  }
  // Normalization and validation logic based on filter type
  const { filter_type, values, defaultValues } = filterData;

  switch (filter_type) {
  case 'BooleanToggleFilterType':
    if (!isBooleanToggleType(values)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      currValue: Boolean(values.state)
    };

  case 'BooleanTriStateSelectorFilterType':
    if (!isBooleanTriStateSelectorType(values) || !isBooleanTriStateSelectorType(defaultValues)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      values: values,
      defaultValues: defaultValues,
      icons: {
        active: filterData.icons?.active,
        inactive: filterData.icons?.inactive,
        indeterminate: filterData.icons?.indeterminate
      }
    };
  case 'FavoriteTriStateSelectorFilterType':
    if (!isBooleanTriStateSelectorType(values) || !isBooleanTriStateSelectorType(defaultValues)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      val: values.state,
      icons: {
        active: filterData.icons?.active,
        inactive: filterData.icons?.inactive,
        indeterminate: filterData.icons?.indeterminate
      }
    };

  case 'StringSelectorFilterType':
    if (!isStringSelectorType(values) || !isStringSelectorType(defaultValues)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      query: values.query,
      defaultQuery: defaultValues.query,
    };
  case 'MultiSelectorFilterType':
    if (!isMultiSelectorType(values) || !isMultiSelectorType(filterData.options)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      currState: values.state,
      options: filterData.options.state,
      defaultValues: filterData.defaultValues.state,
    };
  case 'WantedMultiSelectorFilterType':
    if (!isMultiSelectorType(values) || !isMultiSelectorType(filterData.options) || !isMultiSelectorType(filterData.defaultValues)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      currState: values.state,
      options: filterData.options.state,
      defaultValues: filterData.defaultValues.state,
    };
  case 'TieredRangeSelectorFilterType':
  case 'RangeInputSelectorFilterType':
  case 'RangeSliderSelectorFilterType':
    if (!isRangeInputSelectorType(values) || !isRangeInputSelectorType(defaultValues)) {
      return null; // Returning null or default values if data is invalid
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      min: Number(values.min),
      max: Number(values.max),
      defaultMin: Number(defaultValues.min),
      defaultMax: Number(defaultValues.max),
      description: filterData.description,
      unit: filterData.unit
    };
  case 'DateRangeSelectorFilterType':
    if (!isDateRangeSelectorType(values) || !isDateRangeSelectorType(defaultValues)) {
      return null;
    }
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
      startDate: values.startDate,
      endDate: values.endDate,
      defaultStartDate: defaultValues.startDate,
      defaultEndDate: defaultValues.endDate,
    };
  default:
    return {
      filter_type: filter_type,
      id: filterData.id,
      title: filterData.title,
      description: filterData.description,
    };
  }
};

function formatDateToYYYYMMDD(date) {
  if (!(date instanceof Date)) {
    return null; // or throw an error, depending on your needs
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth is zero-indexed
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}
