import React, { FC } from 'react';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';
import { useFilteringContext } from '../../contexts/Filtering';
import { Button } from 'primereact/button';

type FilterSummaryProps = {
  showMapFilterDisclaimer?: boolean
}

const FilterSummaryAndActions: FC<FilterSummaryProps> = ({ showMapFilterDisclaimer = true }) => {
  const { filteringDispatch, state } = useFilteringContext();
  const { noFiltersSet, defaultFiltersSet } = state;
  const {
    totalPropertiesCount,
    criteriaMatchedPropertiesCount,
    isLassoSet
  } = usePropertyViewerContext();

  const baseClass = 'rounded text-xs font-medium text-white shadow-sm !p-[6px]';
  const disabledClass = 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-70 border-transparent';
  const clickableClass = 'hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600';
  return (
    <div className='flex flex-row'>
      <div className='flex justify-between flex-col m-2 text-sm text-black-400'>
        {showMapFilterDisclaimer && isLassoSet && (
          <div className='text-green-800'>
            Being Filtered by the Map View
          </div>
        )}
        <div>Total Properties in List: {totalPropertiesCount}</div>
        <div>Filtered Properties count: {criteriaMatchedPropertiesCount}</div>
      </div>
      <div className='flex flex-col'>
        {showMapFilterDisclaimer && isLassoSet &&
        <Button
          type='button'
          id='clear-lasso-filter-button'
          label='Clear Map View Filter'
          onClick={() => filteringDispatch({ type: 'SET_GIS_FILTER', gisFilter: null })}
          className={`${baseClass} ${clickableClass}`}
        />
        }
        <Button
          type="button"
          id="clear-filters-button"
          disabled={noFiltersSet}
          label="Clear Filters"
          onClick={() => filteringDispatch({ type: 'RESET_FILTERS' })}
          className={`${baseClass} mt-1 ${noFiltersSet ? disabledClass : clickableClass}`}
        />
        <Button
          type="button"
          id="reset-to-default-filters-button"
          disabled={defaultFiltersSet}
          label="Default Filters"
          onClick={() => filteringDispatch({ type: 'DEFAULT_FILTERS' })}
          className={`${baseClass} mt-1 ${defaultFiltersSet ? disabledClass : clickableClass}`}
        />
      </div>
    </div>
  );
};

export default FilterSummaryAndActions;
