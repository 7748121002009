
import React, { FC } from 'react';
import FullAddressSearch from '../propertySearch/Components/FullAddressSearch';
import FilterSummaryAndActions from '../common/FilterSummaryAndActions';

type HeaderProps = {
  showMapFilterDisclaimer?: boolean
};

const Header: FC<HeaderProps> = ({ showMapFilterDisclaimer = true }) => {
  return (
    <div id="property-list-viewer-header" className="flex justify-between items-center h-18">
      <FullAddressSearch />
      <FilterSummaryAndActions showMapFilterDisclaimer={showMapFilterDisclaimer} />
    </div>
  );
};

export default Header;
