import { api } from '../configs/axiosConfigs';

let abortController = null;

export const getProperties = async ({ ransackObj, rows, page, sortMeta }) => {
  // Cancel any in-flight requests
  if (abortController) {
    abortController.abort();
  }

  // Create new controller for this request
  abortController = new AbortController();

  try {
    // Build query params
    const params = new URLSearchParams();
    if (rows != null && page != null) {
      params.append('per_page', rows.toString());
      params.append('page', page.toString());
    }

    if (sortMeta) {
      params.append('sort_meta', JSON.stringify(sortMeta));
    }

    // Make request
    const response = await api.post(
      `/properties_with_filtering.json?${params.toString()}`,
      ransackObj,
      { signal: abortController.signal }
    );

    return response;

  } finally {
    abortController = null;
  }
};
