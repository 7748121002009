import React, { type FC } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { LoadSearchCriteriaConfirmationModalProps } from '../../../../utility/types';
import { formatDate } from '../../../../utility/utility_functions';

const LoadSearchCriteriaConfirmationModal: FC<LoadSearchCriteriaConfirmationModalProps> = ({ isOpen, onClose, onConfirm, criteriaName, criteria }) => {
  function formatCriteria(criteria: any) {
    return (
      <div id="load-criteria-modal-criteria-container">
        {Object.entries(criteria).map(([ key, value ]: [ string, any ]) => {
          if (key === 'id' || key === 'user_id' || key === 'title' || !value) {
            return null;
          }
          if (key === 'created_at' || key === 'updated_at') {
            return <p key={key}>{key}: {formatDate(value)}</p>;
          }
          if (Array.isArray(value)) {
            if (value.length) {
              return <p key={key}>{key}: {value.join(', ')}</p>;
            }
            else {
              return null;
            }
          }
          if (key === 'favorite') {
            return value === true ? <p key={key}>{key}: value</p> : null;
          }
          if (value) {
            return <p key={key}>{key}: {value}</p>;
          }
          return null;
        })}
      </div>
    );
  }
  if (!isOpen) {
    return null;
  }

  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Confirm to load search criteria: {criteriaName}
                </h3>
                <div id="load-criteria-modal-criteria-container" className="mt-2">
                  {formatCriteria(criteria)}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                onClick={onConfirm}
              >
              Load Search Criteria
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                onClick={onClose}
              >
              Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadSearchCriteriaConfirmationModal;
