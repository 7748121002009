import React, { type FC } from 'react';
import MlsImagesViewer from '../common/MlsImagesViewer';
import AvmMap from '../common/AvmMap';
import UserImagesViewer from '../common/UserImagesViewer';
import { LoadingSpinner } from '../icons/OurIcons';
import { AvmCompForMap } from '../../utility/types';
import NearbyListingMap from '../common/NearbyListingsMap';

type ImagesMapDisplayProps = {
  avmResult: any;
  avmCompsForMap?: AvmCompForMap[];
  viewMlsImages: boolean;
  viewMap: boolean;
  viewNearbyListingsMap: boolean;
  viewUserImages: boolean;
  galleriaRef: any;
  mlsPropertyImages: any[];
  userImages: any[];
  isLoading: boolean;
  error: string;
}

const ImagesMapDisplay: FC<ImagesMapDisplayProps> = ({ avmResult, avmCompsForMap, viewMlsImages, viewMap, viewNearbyListingsMap, viewUserImages, galleriaRef, mlsPropertyImages, userImages, isLoading, error }) => {

  if (isLoading) {
    return (
      <div role="status">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className='flex items-center justify-center h-96'>
        <div className='text-center'>{error}</div>
      </div>
    );
  }

  if (!viewMlsImages && !viewUserImages && !viewMap && !viewNearbyListingsMap) {
    return (
      <div className='flex items-center justify-center h-96'>
        <div className='text-center'>No Images Data</div>
      </div>
    );
  }

  return (
    <div className='mt-2 min-w-max h-full p-3'>
      {viewMlsImages &&
        <div>
          <MlsImagesViewer
            propertyImages={mlsPropertyImages}
            galleriaRef={galleriaRef}
          />
        </div>
      }
      {viewUserImages &&
        <div>
          <UserImagesViewer propertyImages={userImages} galleriaRef={galleriaRef} />
        </div>
      }
      {viewMap &&
        <div className='h-96'>
          <AvmMap property={avmResult?.result?.property} comps={avmCompsForMap} />
        </div>
      }
      {viewNearbyListingsMap &&
        <div className='h-96'>
          <NearbyListingMap property={avmResult?.result?.property} />
        </div>
      }
    </div>
  );
};

export default ImagesMapDisplay;
