import React from 'react';

import { useFilteringContext, FilterComponentRegistry } from '../../../../contexts/Filtering'; // Assuming the context setup
import FilterFactory from './FilterFactory';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const FilterDisplay = () => {
  const { state, activeFilters } = useFilteringContext();
  const filters = state.filters;

  return (
    <div className="grid grid-cols-2 px-5 py-5 gap-x-2 gap-y-5">
      {Object.entries(filters).map(([ id, filterData ]) => {
        if (!id || id === 'fullAddress') {
          return null;
        }
        const FilterComponent = FilterComponentRegistry[filterData.filter_type];
        if (!FilterComponent) {
          return null;
        }

        return (
          <div key={`FilterComponentWrapper-${id}`} className="w-full rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md">
            <Disclosure>
              <Disclosure.Button className="flex justify-between items-center">
                {filterData.title}
                <ChevronDownIcon className="w-5" />
                <div className="text-s text-blue-500 justify-end font-bold">
                  {activeFilters.has(id) && 'Active'}
                </div>
              </Disclosure.Button>
              <Disclosure.Panel>
                <FilterFactory key={`FilterDisplay-${id}`} id={id} filterData={filterData} />
              </Disclosure.Panel>
            </Disclosure>
          </div>
        );

      })}
    </div>
  );
};

export default FilterDisplay;
