import React, { type FC } from 'react';
import { Dialog, } from '@headlessui/react';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { TableColumn } from '../../redux/state/columnsSlice';
import { useFilteringContext } from '../../contexts/Filtering';

type SortModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SortModal: FC<SortModalProps> = ({ isOpen, setIsOpen }) => {
  const { columns } = useSelector((state: RootState) => state.columns);
  const { handleSort, sorting } = useFilteringContext();

  const handleFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedColumn = columns?.find(col => col.field === event.target.value);
    if (selectedColumn) {
      handleSort({ field: selectedColumn.field, direction: 'asc' });
    }
  };

  const handleDirectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDirection = event.target.value;
    if (selectedDirection) {
      handleSort({ field: sorting?.field, direction: selectedDirection as 'asc' | 'desc' });
    }
  };

  const clearSort = () => {
    handleSort({ field: 'removeSort', direction: null });
  };

  return (
    <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10" id='SortModal-container'>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel
            id='SortModal-panel'
            className="relative transform overflow-hidden rounded-lg shadow-inner bg-slate-100 border-4 border-solid border-slate-200 px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className='absolute top-0 left-0 p-2 cursor-pointer hover:bg-slate-100 hover:text-blue-500' onClick={() => setIsOpen(false)}>
                x
            </div>
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 mb-4">
                      Select a sort field
                </Dialog.Title>
                <div className="mt-2 flex flex-row gap-2 justify-center">
                  <select
                    id='SortModal-sort-field-select'
                    className="flex-grow-4 block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    onChange={handleFieldChange}
                    value={sorting?.field || 'Select a Sort Field'}
                  >
                    <option value="">Sort Field</option>
                    {columns?.filter(col => col.visible && col.field !== 'full_address' ).map((col: TableColumn) => (
                      <option key={`sort-modal-column-${col.field}`} value={col.field}>
                        {col.header}
                      </option>
                    ))}
                  </select>
                  <select
                    id='SortModal-sort-direction-select'
                    className="flex-grow-1 block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                    onChange={handleDirectionChange}
                    value={sorting?.direction || 'Select a Sort Direction'}
                  >
                    <option value="">Sort Direction</option>
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 flex justify-center">
              <button
                type="button"
                onClick={clearSort}
                className="inline-flex w-[200px] justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                    Clear sort
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default SortModal;
