import React, { FC } from 'react';
import { TitleProps } from '../utility/types';

const Title: FC<TitleProps> = ({ title, subtitle, isLoggedIn }) => {

  return (
    <>
      <h1 className="font-bold text-4xl text-blue-500">{title}</h1>
      <h2 className="p-1">{subtitle}</h2>
      <div className="flex justify-center mt-2">
        <div>
          {isLoggedIn ? (
            <a href="/list-sorts" className="ml-2 rounded-lg py-3 px-5 bg-blue-500 text-white inline-block font-medium hover:bg-slate-600">
              Enter
            </a>
          ) : (
            <a href="/users/sign_in" className="ml-2 rounded-lg py-3 px-5 bg-blue-500 text-white inline-block font-medium hover:bg-slate-600">
              Go login
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default Title;
