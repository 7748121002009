import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production'
  ? 'https://app.reix.co' // Production URL
  : 'http://localhost:3000'; // Development URL

const csrfToken =
  typeof document !== 'undefined' &&
  document.querySelector('meta[name="csrf-token"]')
    ? document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    : null;
// initializing the axios instance with custom configs
export const api = axios.create({
  withCredentials: true,
  // adding a custom language header
  headers: {
    'Custom-Language': 'en',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken,
  },
  baseURL: baseURL,
});

export const apiFormData = axios.create({
  withCredentials: true,
  // adding a custom language header
  headers: {
    'Custom-Language': 'en',
    'Content-Type': 'multipart/form-data',
    'X-CSRF-Token': csrfToken,
  },
  baseURL: baseURL,
});
